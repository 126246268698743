/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import Accordion from './../../components/LandingPage/Accordion';
import NewChart from './DifferentChart';
import TreeMap from './TreeMap';
import GenericUSRevparGrowthChart from '../LandingPage/GenericUSRevparGrowthChart';
import DashboardContext from '../../context/dashboard/dashboardContext';
import MarketpageContext from '../../context/marketpage/marketpageContext';

const AccordionChart = ({ marketId }) => {
  const dashboardContext = useContext(DashboardContext);
  const { tableData, variableData, segmentationData } = dashboardContext;
  const marketpageContext = useContext(MarketpageContext);
  const marketType = segmentationData.find((e) => e.id === marketId).seg_type;
  const {
    variableAvg,
    topVariableData,
    topVariableList,
    variableVis1,
  } = marketpageContext; // market name
  const selectedMarketName = tableData.find((m) => m.id === marketId).name;
  // console.log(marketId);
  // console.log(topVariableData[marketId]);
  const selectedMarketTopVariableData = topVariableData[marketId]['variables'];
  const selectedMarketTopVariableList = topVariableList[marketId];
  
  if (!selectedMarketTopVariableList.includes('hotel-pipeline-pct')) {  // always display hotel-pipeline-pct
    selectedMarketTopVariableList.push('hotel-pipeline-pct');
  }
  
  selectedMarketTopVariableList.forEach((item, index) => {
    if (!(item in selectedMarketTopVariableData)) {
      // console.log(item);
    }
  });
  // console.log(variableVis1);
  var fullVariableList = {};
  selectedMarketTopVariableList.forEach((item1, index1) => {
    fullVariableList[item1] = [];
    // console.log(item1);
    // console.log(variableVis1);
    // console.log(Object.keys(variableVis1['data']).includes(item1));
    if (variableVis1[item1]) {
      variableVis1[item1].forEach((item2, index2) => {
        fullVariableList[item1].push(item2);
      });
    }
  });
  const data = [];
  // console.log(selectedMarketTopVariableData);
  for (let i = 2004; i <= 2023; i++) {
    let s = i.toString();
    let tmp = {};
    tmp['year'] = s;
    Object.entries(fullVariableList).forEach(([topVar, subVarLists]) => {
      if (topVar === 'hotel-pipeline-pct') {
        // console.log(topVar)
        // console.log(subVarLists)
        subVarLists.forEach((subVarList) => {
          // console.log(subVarList)
          subVarList.forEach((subVar) => {
            if (subVar.startsWith('avg-')) {
              // console.log(variableAvg.data[subVar]);
              // console.log(subVar);
              // console.log(s);
              // if (s in variableAvg[subVar]) {
              tmp[subVar] = variableAvg[subVar][s];
              // }
            } else {
              // console.log(selectedMarketTopVariableData[topVar][subVar]);
              // console.log(selectedMarketTopVariableData);
              // console.log(topVar);
              // console.log(subVar);
              // console.log(s);
              // if (s in selectedMarketTopVariableData[topVar][subVar]) {
              tmp[subVar] = selectedMarketTopVariableData[topVar][subVar][s];
              // }
            }
          });
        });
      }
    });
    data.push(tmp);
  }
  // console.log(data);

  var initOpen = Array(selectedMarketTopVariableList.length).fill(false);
  // initOpen[0] = true; // keep first var open
  selectedMarketTopVariableList.forEach((item1, index1) => {
    if (item1 === 'advanced-industry-share' || item1 === 'hotel-pipeline-pct') {
      initOpen[index1] = true;
    }
  });
  const [open, setOpen] = useState(initOpen);

  useEffect(() => {
    setOpen(initOpen);
  }, [marketId]);

  return (
    <Fragment>
      <div
        css={css`
          margin: 0 auto;
          max-width: 100%;
        `}
      >
        <h2 style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          Top Impactful Variables for {selectedMarketName}
        </h2>
        <div>
          {selectedMarketTopVariableList.map((item, index) => {
            return (
              <>
                <Accordion
                  name={item}
                  isOpen={open[index]}
                  onToggle={() =>
                    setOpen([
                      ...open.slice(0, index),
                      !open[index],
                      ...open.slice(index + 1),
                    ])
                  }
                  heading={variableData.find((e) => e.id === item).l_name}
                >
                  <div
                    css={css`
                      background: white;
                      padding: 10px 20px 20px;
                    `}
                  >
                    {item === 'advanced-industry-share' && (
                      <TreeMap market={marketId} />
                    )}
                    {/* {(item === 'us-revpar-forecast' || item === 'prior-yr-us-revpar-forecast') && (
                      <GenericUSRevparGrowthChart />
                    )} */}
                    {fullVariableList[item].map((subitem, subindex) => (
                      <NewChart
                        data={data}
                        topVar={item}
                        subVar={subitem}
                        marketType={marketType}
                      />
                    ))}
                  </div>
                </Accordion>
              </>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
export default AccordionChart;
