import React, { useReducer } from 'react';
import DashboardContext from './dashboardContext';
import DashboardReducer from './dashboardReducer';

import {
  SET_VARIABLE,
  SET_YEAR,
  SET_VISUALIZATION,
  GET_DATATABLE,
  FLIP_MENU_STATUS,
  GET_MARKET_DATA,
  GET_SEGMENTATION,
  GET_US_REVPAR_GROWTH,
  GET_VARIABLES,
  GET_FULL_DATATABLE,
  GET_VARIABLE_CSV,
  GET_ORIGINAL_DATATABLE,
  GET_TOOLTIP,
  GET_MARKET_CATEGORY
} from '../types';
import axios from 'axios';

// const api_prefix = '/api/v1/landingpage';

const api_prefix =
  process.env.NODE_ENV === 'development'
    ? `http://localhost:3000/api/v5/landingpage`
    : `/api/landingpage`;

// const fullTableData = require('./../../contents/LandingPage/static/data-table.json');
// const usRevparData = require('./../../contents/LandingPage/static/us-revpar-growth.json');
// const segmentation = require('./../../contents/LandingPage/static/segmentation.json');
// const allVariableData = require('./../../contents/LandingPage/static/variables.json');
// const allMarketData = require('./../../contents/LandingPage/static/market-data.json');

const DashboardState = (props) => {
  const initialState = {
    fullTableData: null,
    originalTableData: null,
    segmentationData: null,
    isMenuOpened: false,
    variableData: null,
    variableCsv: null,
    tooltip: null,
    tableData: null,
    usRevparData: null,
    marketData: null,
    marketCategory: null,
    selectedVariable: 0,
    selectedVariableColor: '#f8f8f8',
    selectedYear: 2023,
    visualization: 'heatmap', // should be heatmap / bubble map
  };

  const [state, dispatch] = useReducer(DashboardReducer, initialState);


  const getMarketCategory = async () => {
    if (state.marketCategory !== null) return;

    try {
      const res = await axios.get(api_prefix + '/market-category');
      // console.log(res.data);
      dispatch({
        type: GET_MARKET_CATEGORY,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTooltip = async () => {
    if (state.tooltip !== null) return;

    // console.log(api_prefix + '/data-table-description');
    try {
      const res = await axios.get(api_prefix + '/data-table-description');
      // console.log(res.data);
      dispatch({
        type: GET_TOOLTIP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getVariableCsv = async () => {
    if (state.variableCsv !== null) return;

    // console.log(api_prefix + '/variables-csv');
    try {
      const res = await axios.get(api_prefix + '/variables-csv');
      // console.log(res.data);
      dispatch({
        type: GET_VARIABLE_CSV,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getVariableData = async () => {
    if (state.variableData !== null) return;

    // console.log(api_prefix + '/variables');
    try {
      const res = await axios.get(api_prefix + '/variables');
      // console.log(res);

      dispatch({
        type: GET_VARIABLES,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getSegmentation = async () => {
    if (state.segmentationData !== null) return;

    // console.log(api_prefix + '/segmentation');
    try {
      const res = await axios.get(api_prefix + '/segmentation');
      // console.log(res);

      dispatch({
        type: GET_SEGMENTATION,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getUsRevparData = async () => {
    if (state.usRevparData !== null) return;

    // console.log(api_prefix + '/us-revpar-growth');
    try {
      const res = await axios.get(api_prefix + '/us-revpar-growth');
      // console.log(res);

      dispatch({
        type: GET_US_REVPAR_GROWTH,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getFullDatatable = async () => {
    if (state.tableData !== null) return;

    // console.log(api_prefix + '/data-table');
    try {
      // console.log(api_prefix + '/data-table');
      const res = await axios.get(api_prefix + '/data-table');
      // console.log(res);
      dispatch({
        type: GET_FULL_DATATABLE,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };


    const getOriginalDatatable = async () => {
      if (state.originalTableData !== null) return;

      // console.log(api_prefix + '/original-datatable');
      try {
        // console.log(api_prefix + '/data-table');
        const res = await axios.get(api_prefix + '/original-datatable');
        // console.log(res);
        dispatch({
          type: GET_ORIGINAL_DATATABLE,
          payload: res.data,
        });
      } catch (err) {
        console.log(err);
      }
    };

  const getDatatable = (input) => {
    let filteredTable;
    if (input == null) {
      filteredTable = state.fullTableData;
    } else {
      let filtered_dict = {};
      for (let x of input) {
        if (!(x.seg in filtered_dict)) {
          filtered_dict[x.seg] = x.value;
        } else {
          filtered_dict[x.seg] += x.value;
        }
      }
      const inSegment = (x, filtered_dict) => {
        for (let key in filtered_dict) {
          if (!filtered_dict[key].includes(x[key])) {
            return false;
          }
        }
        return true;
      };
      // console.log(filtered_dict);
      const filtered_markets = state.segmentationData
        .filter((x) => inSegment(x, filtered_dict))
        .map((x) => x.id);

      filteredTable = state.fullTableData.filter((x) =>
        filtered_markets.includes(x.id)
      );
    }

    dispatch({
      type: GET_DATATABLE,
      payload: filteredTable,
    });
  };

  const getMarketData = async () => {
    if (state.marketData !== null) return;

    // console.log(api_prefix + '/market-data');
    try {
      const res = await axios.get(api_prefix + '/market-data');
      // console.log(res);

      dispatch({
        type: GET_MARKET_DATA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setVariable = (variable, color) => {
    dispatch({
      type: SET_VARIABLE,
      payload: [variable, color],
    });
  };

  const flipMenuStatus = () => {
    dispatch({
      type: FLIP_MENU_STATUS,
    });
  };
  const setYear = (year) => {
    dispatch({
      type: SET_YEAR,
      payload: year,
    });
  };

  const setVisualization = (vis) => {
    // console.log(vis);
    dispatch({
      type: SET_VISUALIZATION,
      payload: vis,
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        segmentationData: state.segmentationData,
        isMenuOpened: state.isMenuOpened,
        variableData: state.variableData,
        variableCsv: state.variableCsv,
        usRevparData: state.usRevparData,
        tableData: state.tableData,
        originalTableData: state.originalTableData,
        tooltip: state.tooltip,
        marketData: state.marketData,
        selectedVariable: state.selectedVariable,
        selectedYear: state.selectedYear,
        selectedVariableColor: state.selectedVariableColor,
        visualization: state.visualization,
        marketCategory: state.marketCategory,
        getMarketCategory,
        setVariable,
        setYear,
        setVisualization,
        getDatatable,
        getUsRevparData,
        getMarketData,
        getSegmentation,
        getVariableData,
        flipMenuStatus,
        getFullDatatable,
        getVariableCsv,
        getOriginalDatatable,
        getTooltip
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
