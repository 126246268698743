import React, { useEffect, useContext } from 'react';
import * as d3 from 'd3';
// import data from './static/variables.csv';
import DashboardContext from '../../context/dashboard/dashboardContext';
import tooltip from 'wsdm-tooltip';
import Information16 from '@carbon/icons-react/lib/information/16';

const SpiralCircle = (props) => {
  const dashboardContext = useContext(DashboardContext);
  const { setVariable, flipMenuStatus, variableCsv } = dashboardContext;
  const width = 800,
    height = 700,
    start = 0,
    end = 2.25,
    numSpirals = 6;
  const margin = { top: 50, bottom: 0, left: 20, right: 200 };
  const theta = function (r) {
    return numSpirals * Math.PI * r;
  };
  const color = d3
    .scaleOrdinal()
    .domain([-1, 0, 1, 2, 3, 4, 5, 6])
    .range([
      '#E73039',
      '#F8B195',
      '#24A148',
      '#F67280',
      '#C06C84',
      '#6C5B7B',
      '#355C7D',
    ]);
  // .range(['#E73039', 'grey', '#24A148']);
  const r = d3.min([width, height]) / 2 - 25;
  const radius = d3.scaleLinear().domain([start, end]).range([10, r]);
  const points = d3.range(start, end + 0.001, (end - start) / 1000); // 1000 points
  const handleClick = (data) => {
    flipMenuStatus();
    // console.log(data);
    setVariable(Number(data.rank) - 1, 'white');
  };

  useEffect(() => {
    const tip1 = tooltip({
      className: 'custom-tooltip',
      styles: { border: '1px solid rgb(204, 204, 204)' },
    });

    var svg = d3
      .select('#spiral-chart')
      .append('svg') // add the canvas
      .attr('width', width + margin.right + margin.left)
      .attr('height', height + margin.left)
      .append('g') // group th shapes
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    var spiral = d3
      .radialLine()
      .curve(d3.curveCardinal)
      .angle(theta)
      .radius(radius);

    var path = svg
      .append('path')
      .datum(points)
      .attr('id', 'spiral')
      .attr('d', spiral)
      .style('fill', 'none')
      .style('stroke', 'lightgrey')
      .style('opacity', 0.7);

    const spiralLength = path.node().getTotalLength(),
      N = 377,
      barWidth = spiralLength / N - 1;
    path
      .style('stroke-dasharray', spiralLength + ' ' + spiralLength)
      .attr('stroke-dashoffset', spiralLength)
      .transition()
      .delay(1000)
      .duration(4000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
    d3.csv()
      .then(function () {
        // console.log(variableCsv);
        let someData = variableCsv;
        // format the data
        someData.forEach(function (d) {
          d.idx = +d.idx;
          d.coef_abs = +d.coef_abs;
          d.coef_sign =
            d.coef_sign === 0
              ? [2, 3, 4, 5, 6][Math.floor(Math.random() * 5)]
              : d.coef_sign;
        });

        var timeScale = d3
          .scaleLinear()
          .domain(
            d3.extent(someData, function (d) {
              return d.idx;
            })
          )
          .range([0, spiralLength]);

        // console.log(r);
        // console.log(numSpirals);
        // yScale for the bar height
        var yScale = d3
          .scalePow()
          .exponent(0.5)
          .domain(d3.extent(someData, (d) => d.coef_abs))
          .range([4, r / numSpirals - 20]);

        svg
          .selectAll('circle')
          .data(someData)
          .enter()
          .append('circle')
          .attr('cx', function (d, i) {
            var linePer = timeScale(d.idx),
              posOnLine = path.node().getPointAtLength(linePer),
              angleOnLine = path.node().getPointAtLength(linePer - barWidth);

            d.linePer = linePer; // % distance are on the spiral
            d.cx = posOnLine.x; // x postion on the spiral
            d.cy = posOnLine.y; // y position on the spiral

            d.a =
              (Math.atan2(angleOnLine.y, angleOnLine.x) * 180) / Math.PI - 90; //angle at the spiral position

            return d.cx;
          })
          .attr('cy', function (d) {
            return d.cy;
          })
          .attr('r', 1)
          .attr('opacity', 0.9)
          .style('fill', (d) => color(d.coef_sign));

        svg
          .selectAll('circle')
          .transition()
          .duration(2000)
          .delay(1)
          // .attr('r', d => yScale(d.coef_abs));
          .attr('r', (d) => (d.coef_abs === 0 ? 1 : yScale(d.coef_abs)));

        //add  label
        svg
          .selectAll(null)
          .data(someData)
          .enter()
          .append('text')
          .attr('x', function (d, i) {
            var linePer = timeScale(d.idx),
              posOnLine = path.node().getPointAtLength(linePer),
              angleOnLine = path.node().getPointAtLength(linePer - barWidth);

            d.linePer = linePer; // % distance are on the spiral
            d.x = posOnLine.x; // x postion on the spiral
            d.y = posOnLine.y; // y position on the spiral

            d.a =
              (Math.atan2(angleOnLine.y, angleOnLine.x) * 180) / Math.PI - 90; //angle at the spiral position

            return d.x + yScale(d.coef_abs) + 1.2;
            // return d.x - yScale(d.coef_abs) * 0.6;
          })
          .attr('y', function (d) {
            return d.y + 6;
          })
          .text((d) => (d.coef_abs !== 0 ? d.name : ''))
          .attr('fill', 'black')
          .style('font-size', function (d) {
            return '15px';
          });

        svg
          .selectAll('circle')
          .filter((d) => d.coef_abs !== 0)
          .on('click', handleClick)
          .on('mouseenter', (d) =>
            tip1.show(`<div class="tooltip-inner"><p><strong>${d.name}</strong></p>
            <p>Rank ${d.rank} out of 20</p><p>Coef: ${d.coef}</p></div>`)
          )
          .on('mousemove', (d) => tip1.position(d3.event))

          .on('mouseover', function (d) {
            d3.select(this)
              .style('stroke', color(d.coef_sign))
              .style('stroke-width', '2px');
          })

          .on('mouseleave', (d) => tip1.hide())

          .on('mouseout', function (d) {
            d3.select(this)
              .style('fill', color(d.coef_sign))
              .style('stroke', 'none');
          });
      })
      .catch(function (err) {
        throw err;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return variableCsv !== null ? (
    <div id="two-columns">
      <div className="helper-text">
        <p>
          {' '}
          <Information16 className="positioned-svg" /> The closer to the center
          of circle, the more significance the variable has on the outlook for
          markets.{' '}
        </p>{' '}
        <p>
          The larger the bubble of the variable, the higher correlation the
          variable has to the RevPAR growth.{' '}
        </p>{' '}
        <p>
          Red means negatively correlated and green means positively correlated.
        </p>
      </div>
      <div id="spiral-chart" align="center"></div>
    </div>
  ) : (
    <p></p>
  );
};

export default SpiralCircle;
