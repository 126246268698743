import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import LandingTable from './LandingTable';
import DashboardContext from '../../context/dashboard/dashboardContext';
import Canvas from './Canvas';
import ArrowUp16 from '@carbon/icons-react/lib/arrow--up/16';
import ArrowDown16 from '@carbon/icons-react/lib/arrow--down/16';
import USRevparGrowthChart from './USRevparGrowthChart';
import { HashLink } from 'react-router-hash-link';

const headers = [
  {
    key: 'adjusted_rank',
    header: 'Rank',
  },
  {
    key: 'name',
    header: 'Market',
  },
  {
    key: 'adjusted_revpar_growth_rate',
    header: 'RevPAR Growth (23-28)',
  },
  {
    key: 'confidence_score',
    header: 'Confidence Score',
  },
  {
    key: 'top_impactful_variables',
    header: 'Top Impactful Variables',
  },
  {
    key: 'adjusted_revpar_growth_rate_5yr',
    header: 'RevPAR Growth (19-28)',
  },
  {
    key: 'adjusted_rank_5yr',
    header: 'Rank (19-28)',
  },

  ,
  // {
  //   key: 'forecast_interval',
  //   header: 'Forecast Interval'
  // },
  {
    key: 'hotel_pipeline_pct',
    header: 'Hotel Pipeline % / rank out of 51',
  },
  {
    key: 'advanced_industry_share',
    header: 'Advanced Ind. Share / rank out of 51',
  },
];

const LandingPageContent = () => {
  const dashboardContext = useContext(DashboardContext);
  const { variableData, tableData } = dashboardContext;
  const rows = tableData;
  const totalRows = 51;
  const showLessRows = 10;
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const getVariableName = (i) => variableData.find((e) => e.id === i).name;

  const NumberBar = (props) => {
    const padding = 10;
    const number = props.correlation > 0 ? props.number : props.number;
    const width =
      props.correlation > 0 ? (55 - props.number) * 2 : props.number * 2;
    return (
      <>
        <svg height="19" width={width + padding}>
          <rect height="19" width={width + padding} fill={props.color} />
        </svg>{' '}
        {number}
      </>
    );
  };

  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -50;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const getRowItems = (rows) =>
    rows.map((row) => ({
      ...row,
      id: row.id,
      adjusted_rank: row.adjusted_rank,
      name: <Link to={`/market/${row.id}`}>{row.name}</Link>,
      adjusted_revpar_growth_rate:
        (row.adjusted_revpar_growth_rate * 100).toFixed(1) + '%',
      adjusted_revpar_growth_rate_5yr:
        (row.adjusted_revpar_growth_rate_5yr * 100).toFixed(1) + '%',
      adjusted_rank_5yr: row.adjusted_rank_5yr,
      // forecast_interval:
      //   (row.forecast_interval[0] * 100).toFixed(1) +
      //   '%' +
      //   ' ~ ' +
      //   (row.forecast_interval[1] * 100).toFixed(1) +
      //   '%',
      confidence_score: row.confidence_score,
      top_impactful_variables: (
        <ul className="horizontal-list">
          {row.top_impactful_variables.slice(0, 4).map((item, index) => (
            <li key={row.id + item[0] + index}>
              {item[1] > 0 ? (
                <ArrowUp16 className="positioned-svg" fill="#24A148" />
              ) : (
                <ArrowDown16 className="positioned-svg" fill="#E73039" />
              )}
              <HashLink
                smooth
                scroll={(el) => scrollWidthOffset(el)}
                to={`/market/${row.id}#${item[0]}`}
              >
                {getVariableName(item[0])}
              </HashLink>
            </li>
          ))}
        </ul>
      ),
      // hotel_pipeline_pct: row.hotel_pipeline_pct
      hotel_pipeline_pct: (
        <NumberBar
          color="#000066"
          correlation={-1}
          number={row.hotel_pipeline_pct_rank}
        />
      ),
      advanced_industry_share: (
        <NumberBar
          color="#A18D50"
          correlation={1}
          number={row.advanced_industry_share_rank}
        />
      ),
    }));

  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner landing-page__r1">
        <div className="bx--col-lg-16">
          {/* <Breadcrumb noTrailingSlash={false}>
            <BreadcrumbItem href="/">Dashboard</BreadcrumbItem>
          </Breadcrumb> */}
          <h1 style={{ paddingBottom: '10px' }}>RevPAR Prediction Analytics</h1>
        </div>
      </div>
      <div className="bx--row landing-page__r2">
        <div className="bx--col-lg-16">
          <LandingTable
            currentPageSize={currentPageSize}
            headers={headers}
            rows={getRowItems(rows)}
          />
          <div id="table-bottom">
            <div id="show-more-or-less">
              {currentPageSize === 10 ? (
                <Link
                  to="#"
                  onClick={() => {
                    setCurrentPageSize(totalRows);
                  }}
                  disabled={false}
                >
                  Show More
                </Link>
              ) : (
                <Link
                  to="#"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setCurrentPageSize(showLessRows);
                  }}
                  disabled={false}
                >
                  Show Less
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bx--row landing-page__r3 section_block">
        <div
          className="bx--col-lg-16"
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <h2>Top Impactful Variables</h2>
        </div>

        <div className="bx--col-md-16 bx--col-lg-16">
          <Canvas />
        </div>
      </div>
      <div className="bx--row landing-page__r4 section_block">
        <div
          className="bx--col-md-16 bx--col-lg-16"
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <h2>US RevPAR Growth</h2>
        </div>
        <div className="bx--col-md-16 bx--col-lg-16">
          <USRevparGrowthChart />
        </div>
      </div>
    </div>
  );
};
export default LandingPageContent;
